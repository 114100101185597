import React, { useState } from "react";
import { Input, Button, Textarea } from "@mantine/core";
import "../styles/TrainingFeaturesPageStyles.scss";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { Menu, MenuItem, ListItemIcon, ListItemText, Bur } from "@mui/material";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { useDisclosure } from "@mantine/hooks";
import { useLocation } from "react-router-dom";
import { Burger, Drawer } from "@mantine/core";

import {
  faLocationDot,
  faPhone,
  faEnvelope,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
      });
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};
const TrainingFeaturesPage = () => {
  const [rescipent, setrescipent] = useState("Institute"); //institute or private coaching
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);
  const navigate = useNavigate();

  const location = useLocation();

  // Function to determine if a path is active
  const isActivePath = (path) => {
    return location.pathname === path;
  };

  const getButtonStyles = (path) => {
    return `text-lg text-gray-500 transition-colors hover:text-gray-300 pb-1 relative ${
      isActivePath(path)
        ? 'after:content-[""] after:absolute text-white after:bottom-0 after:left-0 after:w-full after:h-0.5 after:bg-[#2FED76]'
        : ""
    }`;
  };

  // Common button styles for mobile navigation
  const getMobileButtonStyles = (path) => {
    return `w-full px-4 py-2 text-lg text-white text-gray-400 transition-colors hover:text-gray-200 relative ${
      isActivePath(path)
        ? 'after:content-[""] after:absolute after:bottom-0 after:left-0 after:w-full after:h-0.5 after:bg-[#2FED76]'
        : ""
    }`;
  };
  return (
    <div className="w-full min-h-screen bg-[#0a0d1e] font-['Anek_Gurmukhi'] overflow-x-hidden ">
      <div className="px-4 pt-6 md:pt-10 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between px-[5%]  mb-8">
          {/* Logo */}
          <button
            onClick={() => navigate("/")}
            className="p-2 bg-transparent hover:cursor-pointer"
          >
            <img src="images/Vector.png" alt="Logo" id="top_logo" />
          </button>

          {/* Mobile Menu Button */}
          <Burger
            opened={drawerOpened}
            onClick={toggleDrawer}
            className="mr-2 lg:hidden"
            color="gray"
            size="sm"
          />

          {/* Desktop Navigation */}
          <div className="items-center hidden space-x-8 lg:flex">
            <button
              onClick={() => navigate("/practice-features")}
              className={getButtonStyles("/practice-features")}
            >
              Practice
            </button>
            <button
              onClick={() => navigate("/training-features")}
              className={getButtonStyles("/training-features")}
            >
              Training
            </button>
            <button
              onClick={() => navigate("/pricing")}
              className={getButtonStyles("/pricing")}
            >
              Pricing
            </button>{" "}
            <button
              onClick={() => navigate("/contactus")}
              className={getButtonStyles("/contactus")}
            >
              Contact us
            </button>
            <div>
              {" "}
              <Button
                // onClick={() => navigate("/chooseuser")}
                onClick={() => navigate("/contactus")}
                color="#2FED76"
                style={{ color: "black" }}
                size="md"
                radius="md"
                fullWidth
                className="pt-1"
              >
                Sign up
              </Button>
            </div>
          </div>

          {/* Mobile Menu Drawer */}
          <Drawer
            opened={drawerOpened}
            onClose={closeDrawer}
            size="100%"
            padding="xl"
            position="right"
            className="lg:hidden"
            overlayProps={{ backgroundOpacity: 0.95, blur: 4 }}
            styles={{
              content: {
                backgroundColor: "#0A0D1E",
              },
              header: {
                display: "none",
              },
              body: {
                padding: 0,
              },
            }}
          >
            <button
              className="absolute top-8 right-[5%] text-gray-500 hover:text-gray-300 mr-4"
              onClick={closeDrawer}
            >
              <FontAwesomeIcon icon={faTimes} className="text-2xl" />
            </button>

            <div className="flex flex-col items-center pt-20 space-y-4">
              <button
                onClick={() => {
                  navigate("/practice-features");
                  closeDrawer();
                }}
                className={getMobileButtonStyles("/practice-features")}
              >
                Practice
              </button>
              <button
                onClick={() => {
                  navigate("/training-features");
                  closeDrawer();
                }}
                className={getMobileButtonStyles("/training-features")}
              >
                Training
              </button>
              <button
                onClick={() => {
                  navigate("/pricing");
                  closeDrawer();
                }}
                className={getMobileButtonStyles("/pricing")}
              >
                Pricing
              </button>
              <button
                onClick={() => {
                  navigate("/contactus");
                  closeDrawer();
                }}
                className={getMobileButtonStyles("/contactus")}
              >
                Contact us
              </button>

              <div className="w-[80%]">
                {" "}
                <Button
                  onClick={() => {
                    navigate("/chooseuser");
                    closeDrawer();
                  }}
                  color="#2FED76"
                  style={{ color: "black" }}
                  size="md"
                  radius="md"
                  fullWidth
                >
                  Sign up
                </Button>
              </div>
            </div>
          </Drawer>
        </div>
        {/* Header Section */}
        <div className="flex flex-col items-center justify-center mt-32 mb-8 md:mt-56 sm:mb-10">
          <h1 className="text-white text-3xl sm:text-4xl md:text-5xl lg:text-[3.5rem] font-bold text-center px-4">
            Training Features
          </h1>
          <p className="text-[#b6b7bd] text-sm sm:text-base md:text-lg lg:text-[1.3rem] font-light text-center mt-4 md:max-w-[50%] lg:max-w-[50%] ">
            Embrace the Future of Football with Athlantix! Our intuitive
            platform is tailored to arm players, coaches , and parents with
            insights like never before. Get ready to gain a competitive edge and
            see the game through the lens of advanced analytics .
          </p>
        </div>

        {/* Features Grid */}
        <div className="flex flex-col items-center justify-center w-full mt-8 space-y-6 sm:space-y-8 md:space-y-10 lg:space-y-12 sm:mt-12 lg:mt-16">
          {/* Top Two Features Container */}
          <div className="flex flex-col lg:flex-row items-center justify-center space-y-6 lg:space-y-0 lg:space-x-6 w-full max-w-[95%] lg:max-w-[90%] xl:max-w-[80%]">
            {/* Left Feature */}
            <div
              className="flex flex-col items-center justify-between w-full p-4 bg-center bg-no-repeat bg-cover lg:w-1/2 rounded-3xl sm:p-6 md:p-8"
              style={{
                backgroundImage: "url('/images/training-feature1-bg.png')",
              }}
            >
              {" "}
              <div className="space-y-3 sm:space-y-4 md:p-8">
                <p className="text-xl font-semibold text-white sm:text-2xl md:text-3xl lg:text-4xl ">
                  Training Session Comparisons
                </p>
                <p className="text-sm text-[#cecfd3] sm:text-base md:text-lg lg:text-xl md:leading-relaxed lg:leading-relaxed">
                  Easily compare players' performance across sessions to track
                  improvements and identify areas for growth.
                </p>
              </div>
              <div className="w-full mb-6 md:my-12">
                <img
                  alt="Training Session Comparisons"
                  src="/images/training-feature1.png"
                  className="w-full max-w-[50vh] h-auto mx-auto object-contain"
                />
              </div>
            </div>

            {/* Right Feature */}
            <div
              className="flex flex-col items-center justify-between w-full p-4 bg-center bg-no-repeat bg-cover lg:w-1/2 rounded-3xl sm:p-6 md:p-8"
              style={{
                backgroundImage: "url('/images/training-feature2-bg.png')",
              }}
            >
              <div className="space-y-3 sm:space-y-4 md:p-10">
                <p className="text-xl font-semibold text-white sm:text-2xl md:text-3xl lg:text-4xl">
                  Customized Training Reports
                </p>
                <p className="text-sm text-[#cecfd3] sm:text-base md:text-lg lg:text-xl md:leading-relaxed lg:leading-relaxed">
                  Receive tailored training reports that highlight key progress
                  metrics and personalized feedback for each player.
                </p>
              </div>
              <div className="w-full mb-6 md:my-[34px]">
                <img
                  alt="Customized Training Reports"
                  src="/images/training-feature2.png"
                  className="w-full max-w-[52vh] h-auto mx-auto object-contain"
                />
              </div>
            </div>
          </div>

          {/* Bottom Feature */}
          <div
            className="flex flex-col lg:flex-row justify-between w-full max-w-[95%] lg:max-w-[90%] xl:max-w-[80%] rounded-3xl bg-cover bg-no-repeat bg-center p-4 sm:p-6 md:p-8"
            style={{
              backgroundImage: "url('/images/training-feature3-bg.png')",
            }}
          >
            <div className="flex flex-col  lg:max-w-[45%] md:p-12">
              <p className="text-xl font-semibold text-white sm:text-2xl md:text-3xl lg:text-5xl md:leading-normal lg:leading-normal">
                Performance Metric
                <br /> Dashboard
              </p>
              <p className="text-sm text-[#cecfd3] sm:text-base md:text-lg lg:text-xl md:leading-relaxed lg:leading-relaxed">
                Access real-time performance stats on a central dashboard,
                giving clear insights into players' strengths and development
                needs.
              </p>
            </div>
            <div className="mt-6 lg:mt-0 md:p-16">
              <img
                alt="Performance Dashboard"
                src="/images/training-feature3.png"
                className=" w-[70vh] h-auto"
              />
            </div>
          </div>
        </div>
      </div>
      {/* Footer */}
      <div className="flex flex-col  space-y-8 bg-[#0B0D16] mt-20 px-4 md:px-20 lg:px-40 py-10">
        {/* Logo */}
        <div className="flex items-center md:ml-40">
          <img
            src="images/new-logo-2.png"
            alt="Athlantix Logo"
            className="h-12"
          />
        </div>

        {/* Content Grid */}
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 lg:gap-16 md:ml-40">
          {/* Contact Information */}
          <div className="flex flex-col space-y-4">
            <div className="flex items-center space-x-3">
              <FontAwesomeIcon
                icon={faLocationDot}
                className="text-[#b5b6b9] text-lg pb-4"
              />
              <p className="text-[#b5b6b9] text-lg">Texas</p>
            </div>
            <div className="flex items-center space-x-3">
              <FontAwesomeIcon
                icon={faPhone}
                className="text-[#b5b6b9] text-lg pb-4"
              />
              <p className="text-[#b5b6b9] text-lg">+21 325 62351 423</p>
            </div>
            <div className="flex items-center space-x-3">
              <FontAwesomeIcon
                icon={faEnvelope}
                className="text-[#b5b6b9] text-lg pb-4"
              />
              <p className="text-[#b5b6b9] text-lg">contact@athlantix.com</p>
            </div>
          </div>

          {/* Navigation Links */}
          <div className="flex flex-col space-y-4">
            <p className="text-[#b5b6b9] text-lg cursor-pointer hover:text-white transition-colors">
              Home
            </p>
            <p className="text-[#b5b6b9] text-lg cursor-pointer hover:text-white transition-colors">
              Features
            </p>
            <p className="text-[#b5b6b9] text-lg cursor-pointer hover:text-white transition-colors">
              Pricing
            </p>
            <p className="text-[#b5b6b9] text-lg cursor-pointer hover:text-white transition-colors">
              Testimonials
            </p>
          </div>

          {/* Additional Contact Information */}
          {/* <div className="flex flex-col space-y-4">
            <div className="flex items-center space-x-3">
              <FontAwesomeIcon
                icon={faLocationDot}
                className="text-[#b5b6b9] text-lg"
              />
              <p className="text-[#b5b6b9] text-lg">Texas</p>
            </div>
            <div className="flex items-center space-x-3">
              <FontAwesomeIcon
                icon={faPhone}
                className="text-[#b5b6b9] text-lg"
              />
              <p className="text-[#b5b6b9] text-lg">+21 325 62351 423</p>
            </div>
            <div className="flex items-center space-x-3">
              <FontAwesomeIcon
                icon={faEnvelope}
                className="text-[#b5b6b9] text-lg"
              />
              <p className="text-[#b5b6b9] text-lg">contact@athlantix.com</p>
            </div>
          </div> */}
        </div>

        {/* Copyright */}
        <div className="flex justify-center pt-4 border-t border-[#2a2c35]">
          <p className="text-[#b5b6b9] text-lg">
            Copyright © 2024 Athlantix. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TrainingFeaturesPage;
