import React, { useState } from "react";
import { Input, Button, Textarea } from "@mantine/core";
import "../styles/PricingPageStyles.scss";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { faCheck, faTimes, faCircle } from "@fortawesome/free-solid-svg-icons";
import { useDisclosure } from "@mantine/hooks";
import { useLocation } from "react-router-dom";
import { Burger, Drawer } from "@mantine/core";
import {
  faLocationDot,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
      });
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};
const PricingPage = () => {
  const [rescipent, setrescipent] = useState("Institute"); //institute or private coaching
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);
  const navigate = useNavigate();

  const location = useLocation();

  // Function to determine if a path is active
  const isActivePath = (path) => {
    return location.pathname === path;
  };

  const getButtonStyles = (path) => {
    return `text-lg text-gray-500 transition-colors hover:text-gray-300 pb-1 relative ${
      isActivePath(path)
        ? 'after:content-[""] text-white after:absolute after:bottom-0 after:left-0 after:w-full after:h-0.5 after:bg-[#2FED76]'
        : ""
    }`;
  };

  // Common button styles for mobile navigation
  const getMobileButtonStyles = (path) => {
    return `w-full px-4 py-2 text-lg text-gray-400 transition-colors hover:text-gray-200 relative ${
      isActivePath(path)
        ? 'after:content-[""] text-white after:absolute after:bottom-0 after:left-0 after:w-full after:h-0.5 after:bg-[#2FED76]'
        : ""
    }`;
  };
  return (
    <div className="w-screen min-h-screen bg-[#0a0d1e] font-['Anek_Gurmukhi'] overflow-x-hidden">
      <div className="pt-6 md:pt-10">
        <div className="flex items-center justify-between px-[5%]  mb-8">
          {/* Logo */}
          <button
            onClick={() => navigate("/")}
            className="p-2 bg-transparent hover:cursor-pointer"
          >
            <img src="images/Vector.png" alt="Logo" id="top_logo" />
          </button>

          {/* Mobile Menu Button */}
          <Burger
            opened={drawerOpened}
            onClick={toggleDrawer}
            className="mr-2 lg:hidden"
            color="gray"
            size="sm"
          />

          {/* Desktop Navigation */}
          <div className="items-center hidden space-x-8 lg:flex">
            <button
              onClick={() => navigate("/practice-features")}
              className={getButtonStyles("/practice-features")}
            >
              Practice
            </button>
            <button
              onClick={() => navigate("/training-features")}
              className={getButtonStyles("/training-features")}
            >
              Training
            </button>
            <button
              onClick={() => navigate("/pricing")}
              className={getButtonStyles("/pricing")}
            >
              Pricing
            </button>{" "}
            <button
              onClick={() => navigate("/contactus")}
              className={getButtonStyles("/contactus")}
            >
              Contact us
            </button>
            <div>
              {" "}
              <Button
                // onClick={() => navigate("/chooseuser")}
                onClick={() => navigate("/contactus")}
                color="#2FED76"
                style={{ color: "black" }}
                size="md"
                radius="md"
                fullWidth
                className="pt-1"
              >
                Sign up
              </Button>
            </div>
          </div>

          {/* Mobile Menu Drawer */}
          <Drawer
            opened={drawerOpened}
            onClose={closeDrawer}
            size="100%"
            padding="xl"
            position="right"
            className="lg:hidden"
            overlayProps={{ backgroundOpacity: 0.95, blur: 4 }}
            styles={{
              content: {
                backgroundColor: "#0A0D1E",
              },
              header: {
                display: "none",
              },
              body: {
                padding: 0,
              },
            }}
          >
            <button
              className="absolute top-8 right-[5%] text-gray-500 hover:text-gray-300 mr-4"
              onClick={closeDrawer}
            >
              <FontAwesomeIcon icon={faTimes} className="text-2xl" />
            </button>

            <div className="flex flex-col items-center pt-20 space-y-4">
              <button
                onClick={() => {
                  navigate("/practice-features");
                  closeDrawer();
                }}
                className={getMobileButtonStyles("/practice-features")}
              >
                Practice
              </button>
              <button
                onClick={() => {
                  navigate("/training-features");
                  closeDrawer();
                }}
                className={getMobileButtonStyles("/training-features")}
              >
                Training
              </button>
              <button
                onClick={() => {
                  navigate("/pricing");
                  closeDrawer();
                }}
                className={getMobileButtonStyles("/pricing")}
              >
                Pricing
              </button>
              <button
                onClick={() => {
                  navigate("/contactus");
                  closeDrawer();
                }}
                className={getMobileButtonStyles("/contactus")}
              >
                Contact us
              </button>

              <div className="w-[80%]">
                {" "}
                <Button
                  onClick={() => {
                    navigate("/chooseuser");
                    closeDrawer();
                  }}
                  color="#2FED76"
                  style={{ color: "black" }}
                  size="md"
                  radius="md"
                  fullWidth
                >
                  Sign up
                </Button>
              </div>
            </div>
          </Drawer>
        </div>
        {/* Header Section */}
        <div className="flex flex-col items-center justify-center mt-32 mb-12 md:mt-56 sm:mb-32">
          <h1 className="text-white text-3xl sm:text-4xl md:text-5xl lg:text-[3.5rem] font-bold text-center px-4">
            Pricing
          </h1>
          <p className="text-[#b6b7bd] text-sm sm:text-base md:text-lg lg:text-[1.3rem] font-light text-center mt-4 md:max-w-[50%] lg:max-w-[50%]">
            Embrace the Future of Football with Athlantix! Our intuitive
            platform is tailored to arm players, coaches , and parents with
            insights like never before. Get ready to gain a competitive edge and
            see the game through the lens of advanced analytics .
          </p>
        </div>

        {/* Pricing Table */}
        <div className="max-w-[71rem] mx-auto px-4 mt-20">
          <div className="grid grid-cols-1 gap-28 md:gap-8 md:grid-cols-3">
            {/* Basic Plan */}
            <div className="flex flex-col items-center p-8  border-2 border-[#15192f] rounded-[3em] bg-transparent transform transition-all duration-200 hover:shadow-[0_40px_40px_rgba(0,0,0,0.16)] hover:-translate-y-5 relative overflow-hidden">
              {/* Gradient overlays */}
              <div className="absolute top-0 left-0 w-full h-32 bg-gradient-to-b from-[#3d425d]/20 to-transparent"></div>
              <div className="absolute bottom-0 left-0 w-full h-32 bg-gradient-to-t from-[#3d425d]/20 to-transparent"></div>

              {/* Content */}
              <h3 className="text-white text-[1.4em] font-semibold relative z-10">
                BASIC
              </h3>
              <div className="relative z-10 mt-4 text-center">
                <span className="text-[3em] text-white">$650</span>
              </div>
              <ul className="relative z-10 flex flex-col justify-center flex-grow w-full my-8 space-y-4">
                <li className="flex items-center text-white">
                  <FontAwesomeIcon
                    icon={faCircle}
                    className="w-2.5 h-2.5 mr-2 text-white"
                  />
                  <span className="font-bold">150 </span> Footage Hours
                </li>
                {[
                  "NCAA Requirement Tracking",
                  "Team Messaging",
                  "Notification System",
                  "Team Training Analyzer",
                  "Team Practice Analyzer",
                  "Parent-Athlete Tracker",
                  "Player Performance Insights",
                ].map((feature) => (
                  <li key={feature} className="flex items-center text-white">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="mr-2 text-white"
                    />
                    {feature}
                  </li>
                ))}
                {["AI Assist", "Expert Analysis & Breakdown"].map((feature) => (
                  <li key={feature} className="flex items-center text-gray-500">
                    <FontAwesomeIcon icon={faTimes} className="mr-2" />
                    {feature}
                  </li>
                ))}
              </ul>
              <a
                href="#"
                className="w-full mt-28 text-decoration-none pt-2 pb-1 px-4 text-black text-[22px] font-semibold bg-white rounded-xl text-center transition-colors hover:bg-gray-200 relative z-10"
              >
                Request Access
              </a>
            </div>

            {/* Standard Plan */}
            <div className="flex flex-col items-center p-8  border-2 border-[#15192f] rounded-[3em] bg-transparent transform transition-all duration-200 hover:shadow-[0_40px_40px_rgba(0,0,0,0.16)] hover:-translate-y-5 -mt-16 mb-16 relative overflow-hidden">
              {/* Gradient overlays */}
              <div className="absolute top-0 left-0 w-full h-32 bg-gradient-to-b from-[#3d425d]/20 to-transparent"></div>
              <div className="absolute bottom-0 left-0 w-full h-32 bg-gradient-to-t from-[#3d425d]/20 to-transparent"></div>

              <h3 className="text-[#858FD8] text-[1.4em] font-semibold">
                STANDARD
              </h3>
              <div className="mt-4 text-center">
                <span className="text-[3em] text-white">$1150</span>
              </div>
              <ul className="flex flex-col justify-center flex-grow w-full my-8 space-y-4">
                <li className="flex items-center text-white">
                  <FontAwesomeIcon
                    icon={faCircle}
                    className="w-2.5 h-2.5 mr-2 text-[#858FD8]"
                  />
                  <span className="font-bold">350</span> Footage Hours
                </li>
                {[
                  "NCAA Requirement Tracking",
                  "Team Messaging",
                  "Notification System",
                  "Team Training Analyzer",
                  "Team Practice Analyzer",
                  "Parent-Athlete Tracker",
                  "Player Performance Insights",
                  "AI Assist",
                ].map((feature) => (
                  <li key={feature} className="flex items-center text-white">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="mr-2 text-[#858FD8]"
                    />
                    {feature}
                  </li>
                ))}
                <li className="flex items-center text-gray-500">
                  <FontAwesomeIcon icon={faTimes} className="mr-2" />
                  Expert Analysis & Breakdown
                </li>
              </ul>
              <a
                href="#"
                className="mt-28 text-decoration-none w-full pt-2 pb-1 px-4 text-black text-[22px] font-semibold bg-[#858FD8] rounded-xl text-center transition-colors hover:bg-[#6b75cf]"
              >
                Request Access
              </a>
            </div>

            {/* Premium Plan */}
            <div className="flex flex-col items-center p-8 border-2 border-[#15192f] rounded-[3em] bg-transparent transform transition-all duration-200 hover:shadow-[0_40px_40px_rgba(0,0,0,0.16)] hover:-translate-y-5 relative overflow-hidden">
              <div className="absolute top-0 left-0 w-full h-32 bg-gradient-to-b from-[#3d425d]/20 to-transparent"></div>
              <div className="absolute bottom-0 left-0 w-full h-32 bg-gradient-to-t from-[#3d425d]/20 to-transparent"></div>

              <h3 className="text-white text-[1.4em] font-semibold">PREMIUM</h3>
              <div className="mt-4 text-center">
                <span className="text-[3em] text-white">$2000</span>
              </div>
              <ul className="flex flex-col justify-center flex-grow w-full my-8 space-y-4">
                <li className="flex items-center text-white">
                  <FontAwesomeIcon
                    icon={faCircle}
                    className="w-2.5 h-2.5 mr-2 text-white"
                  />
                  <span className="font-bold">850 </span> Footage Hours
                </li>
                {[
                  "NCAA Requirement Tracking",
                  "Team Messaging",
                  "Notification System",
                  "Team Training Analyzer",
                  "Team Practice Analyzer",
                  "Parent-Athlete Tracker",
                  "Player Performance Insights",
                  "AI Assist",
                  "Expert Analysis & Breakdown",
                ].map((feature) => (
                  <li key={feature} className="flex items-center text-white">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="mr-2 text-white"
                    />
                    {feature}
                  </li>
                ))}
                <div className="flex flex-col mt-4 ml-10 space-y-4">
                  <li className="flex items-center text-white">
                    <FontAwesomeIcon
                      icon={faCircle}
                      className="w-2.5 h-2.5 mr-2 text-white"
                    />
                    $200 per each team
                  </li>
                  <li className="flex text-white">
                    <FontAwesomeIcon
                      icon={faCircle}
                      className="w-2.5 h-2.5 mr-2 pt-2 text-white"
                    />
                    $600 per each program (each program consists of 4 teams)
                  </li>
                </div>
              </ul>
              <a
                href="#"
                className="w-full mt-28 text-decoration-none pt-2 pb-1 px-4 text-black text-[22px] font-bold bg-[#13bd53] rounded-xl text-center transition-colors hover:bg-[#089940]"
              >
                Request Access
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* Footer */}
      <div className="flex flex-col  space-y-8 bg-[#0B0D16] mt-20 px-4 md:px-20 lg:px-40 py-10">
        {/* Logo */}
        <div className="flex items-center md:ml-40">
          <img
            src="images/new-logo-2.png"
            alt="Athlantix Logo"
            className="h-12"
          />
        </div>

        {/* Content Grid */}
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 lg:gap-16 md:ml-40">
          {/* Contact Information */}
          <div className="flex flex-col space-y-4">
            <div className="flex items-center space-x-3">
              <FontAwesomeIcon
                icon={faLocationDot}
                className="text-[#b5b6b9] text-lg pb-4"
              />
              <p className="text-[#b5b6b9] text-lg">Texas</p>
            </div>
            <div className="flex items-center space-x-3">
              <FontAwesomeIcon
                icon={faPhone}
                className="text-[#b5b6b9] text-lg pb-4"
              />
              <p className="text-[#b5b6b9] text-lg">+21 325 62351 423</p>
            </div>
            <div className="flex items-center space-x-3">
              <FontAwesomeIcon
                icon={faEnvelope}
                className="text-[#b5b6b9] text-lg pb-4"
              />
              <p className="text-[#b5b6b9] text-lg">contact@athlantix.com</p>
            </div>
          </div>

          {/* Navigation Links */}
          <div className="flex flex-col space-y-4">
            <p className="text-[#b5b6b9] text-lg cursor-pointer hover:text-white transition-colors">
              Home
            </p>
            <p className="text-[#b5b6b9] text-lg cursor-pointer hover:text-white transition-colors">
              Features
            </p>
            <p className="text-[#b5b6b9] text-lg cursor-pointer hover:text-white transition-colors">
              Pricing
            </p>
            <p className="text-[#b5b6b9] text-lg cursor-pointer hover:text-white transition-colors">
              Testimonials
            </p>
          </div>

          {/* Additional Contact Information */}
          {/* <div className="flex flex-col space-y-4">
            <div className="flex items-center space-x-3">
              <FontAwesomeIcon
                icon={faLocationDot}
                className="text-[#b5b6b9] text-lg"
              />
              <p className="text-[#b5b6b9] text-lg">Texas</p>
            </div>
            <div className="flex items-center space-x-3">
              <FontAwesomeIcon
                icon={faPhone}
                className="text-[#b5b6b9] text-lg"
              />
              <p className="text-[#b5b6b9] text-lg">+21 325 62351 423</p>
            </div>
            <div className="flex items-center space-x-3">
              <FontAwesomeIcon
                icon={faEnvelope}
                className="text-[#b5b6b9] text-lg"
              />
              <p className="text-[#b5b6b9] text-lg">contact@athlantix.com</p>
            </div>
          </div> */}
        </div>

        {/* Copyright */}
        <div className="flex justify-center pt-4 border-t border-[#2a2c35]">
          <p className="text-[#b5b6b9] text-lg">
            Copyright © 2024 Athlantix. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PricingPage;
