import React, { useState, useEffect } from "react";
import "../styles/ContactUsStyles.scss";
import { Input } from "@mantine/core";
import { Button } from "@mantine/core";
import { PasswordInput } from "@mantine/core";
import { Checkbox } from "@mantine/core";
import { useSelector, useDispatch } from "react-redux";
import { login, reseter } from "../auth/authslice";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { useDisclosure } from "@mantine/hooks";
import { Modal } from "@mantine/core";
import { toast } from "react-toastify";
import { NativeSelect } from "@mantine/core";
import { Radio } from "@mantine/core";
import { Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import { faAngleDown, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Burger, Drawer } from "@mantine/core";
const port = process.env.REACT_APP_IP_ADDRESS;
const API_URL = `${port}/api/ContactUs/RequestDemo`;

const ContactUs = (props) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    state: "",
    postalCode: "",
    organizationName: "",
    organizationLevel: "",
    role: "",
    discussion: "",
    contact: "",
  });

  // const [opened, { open, close }] = useDisclosure(false);

  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    postalCode,
    state,
    organizationName,
    organizationLevel,
    role,
    discussion,
    contact,
  } = formData;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if any required field is empty
    for (const key in formData) {
      if (!formData[key]) {
        const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
        toast.error(
          <>
            <img src="images/new-logo.png" className="toast_logo" />
            <p className="mt-3">{`${capitalizedKey} is required`}</p>
          </>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
        return;
      }
    }

    // If all fields are filled, proceed with sending the data
    axios
      .post(API_URL, formData)
      .then((response) => {
        console.log(response.data);
        console.log(API_URL);

        // Reset form fields
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",

          state: "",
          postalCode: "",
          organizationName: "",
          organizationLevel: "",
          role: "",
          discussion: "",
          contact: "",
        });
        // Display success toast message
        toast.success(
          <>
            <img src="images/new-logo.png" className="toast_logo" />
            <p className="mt-3">Form Submitted Successfully!</p>
          </>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
        // Display error toast message
        toast.error("Error submitting form");
      });
  };

  //--------------------------------navigation----------------------------------//
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);

  const location = useLocation();

  // Function to determine if a path is active
  const isActivePath = (path) => {
    return location.pathname === path;
  };

  const getButtonStyles = (path) => {
    return `text-lg text-gray-500 transition-colors hover:text-gray-300 pb-1 relative ${
      isActivePath(path)
        ? 'after:content-[""] after:absolute text-white after:bottom-0 after:left-0 after:w-full after:h-0.5 after:bg-[#2FED76]'
        : ""
    }`;
  };

  // Common button styles for mobile navigation
  const getMobileButtonStyles = (path) => {
    return `w-full px-4 py-2 text-lg text-gray-400 transition-colors hover:text-gray-200 relative ${
      isActivePath(path)
        ? 'after:content-[""] after:absolute  text-white after:bottom-0 after:left-0 after:w-full after:h-0.5 after:bg-[#2FED76]'
        : ""
    }`;
  };

  //--------------------------------------input------------------------------//

  const roleOptions = [
    "Select role",
    "Analyst",
    "Assistant Coach",
    "Athlete",
    "Athletic Director",
    "Board Member",
    "Director",
    "Head Coach",
    "Manager",
    "Office Administrator",
    "Parent/Fan",
    "President",
    "Recruiter",
    "Video Coordinator",
  ];

  // Common styles for all inputs
  const inputClasses =
    "w-full h-10 px-0 bg-transparent border-b border-[#393c52]  text-white placeholder:text-[#393c52] focus:outline-none focus:border-[#cbd5e1] transition-colors duration-200";
  const labelClasses = "block text-md font-medium text-[#cbd5e1] mb-2";
  const wrapperClasses = "w-full md:w-[50vh]";
  return (
    <>
      <div className="contact-container">
        <div className="pt-6 md:pt-10">
          <div className="flex items-center justify-between px-[5%]  mb-8">
            {/* Logo */}
            <button
              onClick={() => navigate("/")}
              className="p-2 bg-transparent hover:cursor-pointer"
            >
              <img src="images/Vector.png" alt="Logo" id="top_logo" />
            </button>

            {/* Mobile Menu Button */}
            <Burger
              opened={drawerOpened}
              onClick={toggleDrawer}
              className="mr-2 lg:hidden"
              color="gray"
              size="sm"
            />

            {/* Desktop Navigation */}
            <div className="items-center hidden space-x-8 lg:flex">
              <button
                onClick={() => navigate("/practice-features")}
                className={getButtonStyles("/practice-features")}
              >
                Practice
              </button>
              <button
                onClick={() => navigate("/training-features")}
                className={getButtonStyles("/training-features")}
              >
                Training
              </button>
              <button
                onClick={() => navigate("/pricing")}
                className={getButtonStyles("/pricing")}
              >
                Pricing
              </button>{" "}
              <button
                onClick={() => navigate("/contactus")}
                className={getButtonStyles("/contactus")}
              >
                Contact us
              </button>
              <div>
                {" "}
                <Button
                  // onClick={() => navigate("/chooseuser")}
                  onClick={() => navigate("/contactus")}
                  color="#2FED76"
                  style={{ color: "black" }}
                  size="md"
                  radius="md"
                  fullWidth
                  className="pt-1"
                >
                  Sign up
                </Button>
              </div>
            </div>

            {/* Mobile Menu Drawer */}
            <Drawer
              opened={drawerOpened}
              onClose={closeDrawer}
              size="100%"
              padding="xl"
              position="right"
              className="lg:hidden"
              overlayProps={{ backgroundOpacity: 0.95, blur: 4 }}
              styles={{
                content: {
                  backgroundColor: "#0A0D1E",
                },
                header: {
                  display: "none",
                },
                body: {
                  padding: 0,
                },
              }}
            >
              <button
                className="absolute top-8 right-[5%] text-gray-500 hover:text-gray-300 mr-4"
                onClick={closeDrawer}
              >
                <FontAwesomeIcon icon={faTimes} className="text-2xl" />
              </button>

              <div className="flex flex-col items-center pt-20 space-y-4">
                <button
                  onClick={() => {
                    navigate("/practice-features");
                    closeDrawer();
                  }}
                  className={getMobileButtonStyles("/practice-features")}
                >
                  Practice
                </button>
                <button
                  onClick={() => {
                    navigate("/training-features");
                    closeDrawer();
                  }}
                  className={getMobileButtonStyles("/training-features")}
                >
                  Training
                </button>
                <button
                  onClick={() => {
                    navigate("/pricing");
                    closeDrawer();
                  }}
                  className={getMobileButtonStyles("/pricing")}
                >
                  Pricing
                </button>
                <button
                  onClick={() => {
                    navigate("/contactus");
                    closeDrawer();
                  }}
                  className={getMobileButtonStyles("/contactus")}
                >
                  Contact us
                </button>

                <div className="w-[80%]">
                  {" "}
                  <Button
                    onClick={() => {
                      navigate("/chooseuser");
                      closeDrawer();
                    }}
                    color="#2FED76"
                    style={{ color: "black" }}
                    size="md"
                    radius="md"
                    fullWidth
                  >
                    Sign up
                  </Button>
                </div>
              </div>
            </Drawer>
          </div>
          <div className="flex flex-col w-full md:flex-row">
            {/* Left Side - Empty/Background Image */}
            <div className="hidden w-full md:block md:w-[40%]">
              {/* Empty space for background image */}
            </div>

            {/* Right Side - Form */}
            <div className="flex flex-col items-start justify-center w-full pt-20 md:w-[60%] px-10">
              <h1 className="mb-4 text-4xl text-white md:text-6xl">
                Let's Get in Touch.
              </h1>
              <h2 className="text-lg md:text-xl text-[#b5b6bb]">
                Questions about our platform?
                <span className="text-[#2fed76]"> We're here to help.</span>
              </h2>

              <form
                onSubmit={handleSubmit}
                className="mt-20 w-full max-w-[100vh]"
              >
                <div className="flex flex-col space-y-6">
                  {/* Name and Role Row */}
                  <div className="flex flex-col space-y-6 md:flex-row md:space-x-10 md:space-y-0">
                    {/* Name Input */}
                    <div className={wrapperClasses}>
                      <label className={labelClasses}>Name</label>
                      <input
                        type="text"
                        name="name"
                        // value={name}
                        onChange={handleChange}
                        placeholder="Full Name"
                        className={inputClasses}
                      />
                    </div>

                    {/* Role Select */}
                    <div className={wrapperClasses}>
                      <label className={labelClasses}>Role</label>
                      <select
                        name="role"
                        value={role}
                        onChange={handleChange}
                        className={`${inputClasses} appearance-none`}
                        style={{
                          WebkitAppearance: "none",
                          MozAppearance: "none",
                        }}
                      >
                        {roleOptions.map((option) => (
                          <option
                            key={option}
                            value={option}
                            className="bg-[#1e2132] text-white py-2 px-4 hover:bg-[#2a2d3d] cursor-pointer"
                          >
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  {/* Email and Phone Row */}
                  <div className="flex flex-col space-y-6 md:flex-row md:space-x-10 md:space-y-0">
                    {/* Email Input */}
                    <div className={wrapperClasses}>
                      <label className={labelClasses}>Email</label>
                      <input
                        type="email"
                        name="email"
                        value={email}
                        onChange={handleChange}
                        className={inputClasses}
                        placeholder="@xyz.com"
                      />
                    </div>

                    {/* Phone Input */}
                    <div className={wrapperClasses}>
                      <label className={labelClasses}>Phone number</label>
                      <input
                        type="tel"
                        name="phoneNumber"
                        value={phoneNumber}
                        onChange={handleChange}
                        className={inputClasses}
                        placeholder="090078601"
                      />
                    </div>
                  </div>

                  {/* Message Input */}
                  <div className="w-full">
                    <label className={labelClasses}>Message</label>
                    <input
                      type="text"
                      name="discussion"
                      value={discussion}
                      onChange={handleChange}
                      className={inputClasses}
                      placeholder="Your message..."
                    />
                  </div>

                  {/* Submit Button */}
                  <div className="flex justify-end">
                    <Button
                      variant="filled"
                      type="submit"
                      color="#2FED76"
                      className="pt-1 text-black"
                      size="md"
                    >
                      Send Message
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
